var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-users"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('span', {
    staticClass: "text-wrap display-1 ma-3"
  }, [_vm._v(_vm._s(_vm.$t('Users')))]), _c('v-col', {
    attrs: {
      "align": "end"
    }
  }, [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info",
      "to": {
        name: 'UserAdd'
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-plus-circle ")]), _vm._v(" " + _vm._s(_vm.$t("AddUser")) + " ")], 1)], 1)], 1), _c('v-divider', {
    staticClass: "mt-2 mb-4"
  }), _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_vm.globalErrorMessage ? _c('v-alert', {
    attrs: {
      "type": "error",
      "dismissible": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.globalErrorMessage) + " ")]) : _vm._e()], 1), _c('v-card', [_c('v-card-title', [_c('v-spacer'), _c('v-spacer'), _c('v-text-field', {
    attrs: {
      "append-icon": "fas fa-search",
      "label": "Search",
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c('v-card-text', [_c('v-data-table', {
    staticClass: "cursorTable",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.users,
      "search": _vm.search,
      "loading": _vm.loadingTable,
      "custom-filter": _vm.customFilter,
      "sort-by": "created",
      "sort-desc": true,
      "footer-props": {
        itemsPerPageOptions: [15, 50, 100, -1]
      }
    },
    on: {
      "click:row": function ($event) {
        return _vm.goToPageItem($event);
      }
    },
    scopedSlots: _vm._u([{
      key: `item.firstname`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'User',
              params: {
                userUUID: `${item._id}`
              }
            },
            "title": item.created
          }
        }, [_vm._v(" " + _vm._s(item.firstname) + " ")])];
      }
    }, {
      key: `item.lastname`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'User',
              params: {
                userUUID: `${item._id}`
              }
            },
            "title": item.created
          }
        }, [_vm._v(" " + _vm._s(item.lastname) + " ")])];
      }
    }, {
      key: `item.email`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'User',
              params: {
                userUUID: `${item._id}`
              }
            },
            "title": item.created
          }
        }, [_vm._v(" " + _vm._s(item.email) + " ")])];
      }
    }, {
      key: `item.created`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'User',
              params: {
                userUUID: `${item._id}`
              }
            },
            "title": item.created
          }
        }, [_vm._v(" " + _vm._s(_vm.$d(new Date(item.created), "long")) + " ")])];
      }
    }, {
      key: `item.action`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('v-btn', {
          staticClass: "white--text",
          attrs: {
            "to": {
              name: 'User',
              params: {
                userUUID: `${item._id}`
              }
            },
            "color": "primary",
            "title": _vm.$t('view')
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-eye ")])], 1)];
      }
    }], null, true)
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }