<template lang="html" src="./pageUsers.template.vue"></template>

<style lang="scss" src="./pageUsers.scss"></style>

<script>
import { ApiErrorParser, CustomFilterForSearchDatatable } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageUsers.i18n');

export default {
  name: 'PageUsers',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      globalErrorMessage: null,
      headers: [],
      loadingTable: false,
      search: '',
      users: [],
    };
  },
  created() {
    this.getAllUser();
  },
  mounted() {
    this.headers = [
      { text: this.$t('firstName'), value: 'firstname' },
      { text: this.$t('lastName'), value: 'lastname' },
      { text: this.$t('email'), value: 'email' },
      { text: this.$t('created'), value: 'created' },
      { text: this.$t('action'), value: 'action' },
    ];
  },
  methods: {
    customFilter(value, search, item) {
      if (!search) return true;
      if ((item._id + ' ' + item.email + ' ' + item.firstname + ' ' + item.lastname + ' ' + item.firstname).toString().toLowerCase().includes(search)) return true;
      return CustomFilterForSearchDatatable.customFilter(value, search, this);
    },
    getAllUser() {
      this.loadingTable = true;
      /**
       * GET ALL USERS
       */
      this.$apiInstance
        .getAllUser()
        .then(
          (data) => {
            this.users = data;
          },
          (error) => {
            /**
             * ERROR GET ALL USERS
             */
            this.globalErrorMessage = ApiErrorParser.parse(error);
          }
        )
        .finally(() => {
          this.loadingTable = false;
        });
    },
    goToPageItem(user) {
      this.$router.push({name: 'User', params: {userUUID: `${user._id}`}});
    },
  },
};
</script>
